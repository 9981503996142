import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";
require("slim-select/dist/slimselect.min.css");

export default class extends Controller {
  static targets = [
    "template",
    "product",
    "items",
    "table",
    "clearancePrice"
  ]

  connect() {
    this.product = null;
  }

  addTableRow(event) {
    event.preventDefault();
    const selectedOptions = Array.from(this.productTarget.selectedOptions);
    selectedOptions.forEach(option => {
      const productId = option.value;
      if (productId) {
        this.getProduct(productId);
      }
    });
  }

  get productSelectController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "product-select"
    );
  }

  addAssociation() {
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      this.itemsTarget.querySelectorAll(".nested-fields").length
    );
    if (this.persistedValue == "true") {
      this.templateTarget.insertAdjacentHTML("beforebegin", content);
    } else {
      this.templateTarget.insertAdjacentHTML("afterend", content);
    }
    this.addFieldValues();
    this.showTable();
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
    this.hideTableIfEmpty();
  }

  addFieldValues() {
    const items = this.itemsTarget.querySelectorAll(".nested-fields");
    if (this.persistedValue == "true") {
      var item = items[items.length - 1];
    } else {
      var item = items[0];
    }

    const productIdInput = item.querySelector("input[name*='product_id']");
    const productNameInput = item.querySelector("input[name*='product_name']");
    const unitCostInput = item.querySelector("input[name*='unit_cost']");
    const clearancePriceTarget = item.querySelector("input[name*='clearance_price']");

    if (productIdInput) productIdInput.value = this.product.id;
    if (productNameInput) productNameInput.value = this.product.name;
    if (unitCostInput) unitCostInput.value = this.product.price.ecommerce_price;
    if (clearancePriceTarget) clearancePriceTarget.value = this.product.price.ecommerce_price;
  }

  addVariants(item) {
    var selectVariant = item.querySelector("select[name*=variants]");
    selectVariant.innerHTML = "";

    var filtered_variants = this.product.variants.filter((variant, i) => {
      return variant.warehouse_id == this.warehouseTarget.value;
    });

    filtered_variants.forEach((variant, i) => {
      selectVariant.innerHTML +=
        '<option value ="' + variant.id + '">' + variant.name + "</option>";
    });

    // add variantvalues with first variant
    var variant = filtered_variants[0];
    if (variant) {
      this.addVariantValues(item, variant.id, "(" + variant.name + ")");
    }
  }

  addVariantValues(item, value, text) {
    item.querySelector("input[name*='product_variant_id']").value = value;
    var value = item.querySelector("input[name*='product_name']").value;

    if (/\(.*\)/.test(value)) {
      item.querySelector("input[name*='product_name']").value = value.replace(
        /\(.*\)/,
        text
      );
    } else {
      item.querySelector("input[name*='product_name']").value =
        value + " " + text;
    }
  }

  getProduct(id) {
    Rails.ajax({
      type: "GET",
      url: `/products/${id}.json`,
      success: (data, status, xhr) => {
        this.product = data;
        this.addAssociation();
      },
    });
  }

  showTable() {
    this.tableTarget.classList.remove("d-none");
  }

  hideTableIfEmpty() {
    if (this.itemsTarget.querySelectorAll(".nested-fields").length === 0) {
      this.tableTarget.classList.add("d-none");
    }
  }
}
